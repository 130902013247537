import Invert from 'lodash/invert'
import Constants from '@constants'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import {
  osArchitectureOptions,
  pcSystemTypeOptions,
  deviceStatusOptions,
  driveTypeOptions,
  pointingTypeOptions,
  memoryTypeOptions,
  userAccountTypeOptions,
  connectionStatusOptions,
  activationStatusOptions,
  assetConditionOptions,
  allocationStatusOptions,
  minQualntityTypeOptins,
  locationPreferenceOptions,
} from '@modules/asset/helpers/option-map'
import {
  patchCategoryOptions,
  patchSeverityOptions,
  patchApprovalStatusOptions,
  patchTestStatusOptions,
  patchDownloadStatusOptions,
  patchSourceOptions,
  patchStatusOptions,
  patchUploadStatusOptions,
  rebootBehaviourOptions,
  patchTypeOptions,
} from '@modules/patch/helpers/options-map'
import { systemHealthSettingOptions } from '@modules/computer/helpers/options-map'
import {
  remoteDeployStageOptions,
  configTypeOptions,
  originOptions as taskTypeOptions,
  configurationTypeOptions,
} from '@modules/remote-deployment/helpers/options-map'
import { OriginOptions } from '@components/data-picker/origin-picker'
import { ProjectSourceOptions } from '@components/data-picker/project-source-picker'
import { depreciationMethodOptions } from '@modules/asset-management/helpers/depreciation'
import {
  baselineStatusOtions,
  assetMovementTypeOptions,
  movementStatusOptions,
  assetMovementStatusOptions,
  assetTypeOptions,
} from '@modules/asset-management/helpers/option-map'

import {
  transformOperator,
  transformOperatorForServer,
  unaryOperators,
  betweenOperators,
  fieldValueOperators,
} from './operator'
import { transformValueTypeForServer, transformValueType } from './value-type'
import {
  transformField,
  buildInputTypeForField,
  getAdditionalFieldProps,
} from './form'

export const OperandTypeMap = {
  variable: 'VariableOperandRest',
  customField: 'CustomFieldOperandRest',
  db: 'DbOperandRest',
  property: 'PropertyOperandRest',
  value: 'ValueOperandRest',
  expression: 'ExpressionOperandRest',
  aggregated: 'AggregatedOperandRest',
  qualification: 'QualificationRest',
  unary: 'UnaryQualificationRest',
}

const __t = getRootTranslator()

export const dropdownTypeMap = {
  booleanOptions() {
    const __rootTc = getRootPluaralTranslator()
    return {
      options: [
        {
          text: __rootTc('yes'),
          key: true,
          singleValueSelection: true,
        },
        {
          text: __rootTc('no'),
          key: false,
          singleValueSelection: true,
        },
      ],
    }
  },
  requestType() {
    const __rootTc = getRootPluaralTranslator()
    return {
      options: [
        {
          text: `${__rootTc('service')} ${__rootTc('request')}`,
          key: 'service_request',
        },
        { text: __rootTc('incident'), key: 'incident' },
      ],
    }
  },
  origin() {
    return {
      options: OriginOptions(),
    }
  },
  projectSource() {
    return {
      options: ProjectSourceOptions(),
    }
  },
  assignee() {
    const __rootT = getRootTranslator()
    return {
      options: [
        {
          text: __rootT('me'),
          key: 'current_user',
          singleValueSelection: true,
        },
      ],
    }
  },
  managedById() {
    const __rootT = getRootTranslator()
    return {
      options: [
        {
          text: __rootT('me'),
          key: 'current_user',
          singleValueSelection: true,
        },
      ],
    }
  },
  groupId() {
    const __rootT = getRootTranslator()
    return {
      options: [
        {
          text: __rootT('my_group'),
          key: 'my_group',
          singleValueSelection: true,
        },
      ],
    }
  },
  managedByGroupId() {
    const __rootT = getRootTranslator()
    return {
      options: [
        {
          text: __rootT('my_group'),
          key: 'my_group',
          singleValueSelection: true,
        },
      ],
    }
  },
  natureOfProblem() {
    const __rootT = getRootTranslator()
    return {
      options: [
        {
          text: __rootT('proactive'),
          key: 'proactive',
        },
        {
          text: __rootT('reactive'),
          key: 'reactive',
        },
      ],
    }
  },
  surveySource() {
    const __rootT = getRootTranslator()
    return {
      options: [
        {
          text: __rootT('manual'),
          key: 'MANUAL',
        },
        {
          text: __rootT('scheduler'),
          key: 'SCHEDULER',
        },
      ],
    }
  },
  surveyStatus() {
    const __rootT = getRootTranslator()
    return {
      options: [
        { id: 'design', key: 'design', text: __rootT('design') },
        {
          id: 'readytolaunch',
          key: 'readytolaunch',
          text: __rootT('ready_to_launch'),
        },
        { id: 'live', key: 'live', text: __rootT('live') },
        { id: 'expired', key: 'expired', text: __rootT('expired') },
        { id: 'closed', key: 'closed', text: __rootT('closed') },
        { id: 'cancelled', key: 'cancelled', text: __rootT('cancelled') },
      ],
    }
  },
  movementStatus() {
    return {
      options: movementStatusOptions(),
    }
  },
  assetMovementStatus() {
    return {
      options: assetMovementStatusOptions(),
    }
  },
  paymentStatus() {
    const __rootT = getRootTranslator()
    return {
      options: [
        { id: 'none', text: __rootT('none'), key: 'none' },
        { id: 'pending', text: __rootT('pending'), key: 'pending' },
        { id: 'done', text: __rootT('done'), key: 'done' },
      ],
    }
  },
  iTSMUserType() {
    const __rootTc = getRootPluaralTranslator()
    return {
      options: [
        { id: 'requester', text: __rootTc('requester'), key: 'requester' },
        { id: 'technician', text: __rootTc('technician'), key: 'technician' },
      ],
    }
  },
  invoiceStatus() {
    const __rootT = getRootTranslator()
    return {
      options: [
        { id: 'none', text: __rootT('none'), key: 'none' },
        { id: 'yes', text: __rootT('yes'), key: 'yes' },
        { id: 'no', text: __rootT('no'), key: 'no' },
      ],
    }
  },
  // @TODO en json load from asset module: sagar
  osArchitecture() {
    return {
      options: osArchitectureOptions(),
    }
  },
  assetCondition() {
    return {
      options: assetConditionOptions(),
    }
  },
  osPlatform() {
    const __rootT = getRootTranslator()
    return {
      options: [
        {
          text: __rootT('windows'),
          key: 'windows',
        },
        {
          text: __rootT('unix_ubuntu'),
          key: 'unix_ubuntu',
        },
        {
          text: __rootT('unix_mint'),
          key: 'unix_mint',
        },
        {
          text: __rootT('unix_centos'),
          key: 'unix_centos',
        },
        {
          text: __rootT('unix_red_hat'),
          key: 'unix_red_hat',
        },
        {
          text: __rootT('unix_open_suse'),
          key: 'unix_open_suse',
        },
        {
          text: __rootT('unix_suse'),
          key: 'unix_suse',
        },
        {
          text: __rootT('unix_debian'),
          key: 'unix_debian',
        },
        {
          text: __rootT('unix_oracle_linux'),
          key: 'unix_oracle_linux',
        },
        {
          text: __rootT('mac'),
          key: 'mac',
        },
      ],
    }
  },
  pcSystemType() {
    return {
      options: pcSystemTypeOptions(),
    }
  },
  deviceStatus() {
    return {
      options: deviceStatusOptions(),
    }
  },
  driveType() {
    return {
      options: driveTypeOptions(),
    }
  },
  pointingType() {
    return {
      options: pointingTypeOptions(),
    }
  },
  memoryType() {
    return {
      options: memoryTypeOptions(),
    }
  },
  userAccountType() {
    return {
      options: userAccountTypeOptions(),
    }
  },
  connectionStatus() {
    return {
      options: connectionStatusOptions(),
    }
  },
  windowsOsActivationType() {
    return {
      options: activationStatusOptions(),
    }
  },
  agentActivationStatus() {
    const __rootT = getRootTranslator()
    return {
      options: [
        {
          text: __rootT('active'),
          key: 'true',
          singleValueSelection: true,
        },
        {
          text: __rootT('in_active'),
          key: 'false',
          singleValueSelection: true,
        },
      ],
    }
  },
  platform() {
    const __rootT = getRootTranslator()
    return {
      options: [
        {
          text: __rootT('windows'),
          key: 'windows',
        },
        {
          text: __rootT('mac'),
          key: 'mac',
        },
        {
          text: __rootT('linux'),
          key: 'unix',
        },
      ],
    }
  },
  mobile_platform() {
    const __rootT = getRootTranslator()
    return {
      options: [
        {
          text: __rootT('android'),
          key: 'android',
        },
        {
          text: __rootT('ios'),
          key: 'ios',
        },
      ],
    }
  },
  callTypeOptions() {
    const __rootT = getRootTranslator()
    return {
      options: [
        {
          text: __rootT('incoming'),
          key: 'incoming',
          singleValueSelection: true,
        },
        {
          text: __rootT('outgoing'),
          key: 'outgoing',
          singleValueSelection: true,
        },
      ],
    }
  },
  blockedStatusOptions() {
    const __rootT = getRootTranslator()
    return {
      options: [
        {
          text: __rootT('blocked'),
          key: 'true',
          singleValueSelection: true,
        },
        {
          text: __rootT('unblocked'),
          key: 'false',
          singleValueSelection: true,
        },
      ],
    }
  },
  authenticationSourceOptions() {
    const __tc = getRootPluaralTranslator()
    return {
      options: [
        {
          text: __tc('ldap'),
          key: 'ldap',
          singleValueSelection: true,
        },
        {
          text: __tc('local'),
          key: 'local',
          singleValueSelection: true,
        },
        {
          text: __tc('azure_ad'),
          key: 'azure_ad',
          singleValueSelection: true,
        },
        {
          text: __tc('one_login'),
          key: 'one_login',
          singleValueSelection: true,
        },
        {
          text: __tc('okta'),
          key: 'okta',
          singleValueSelection: true,
        },
        {
          text: __tc('key_cloak'),
          key: 'key_cloak',
          singleValueSelection: true,
        },
        {
          text: __tc('telegram'),
          key: 'telegram',
          singleValueSelection: true,
        },
        {
          text: __tc('whatsapp'),
          key: 'whatsapp',
          singleValueSelection: true,
        },
        {
          text: __tc('messenger'),
          key: 'messenger',
          singleValueSelection: true,
        },
      ],
    }
  },
  // patch options
  patchUpdateCategory() {
    return {
      options: patchCategoryOptions(),
    }
  },
  patchSeverity() {
    return {
      options: patchSeverityOptions(),
    }
  },
  patchApprovalStatus() {
    return {
      options: patchApprovalStatusOptions(),
    }
  },
  patchTestStatus() {
    return {
      options: patchTestStatusOptions(),
    }
  },
  baselineStatus() {
    return {
      options: baselineStatusOtions(),
    }
  },
  assetMovementTypeOptions() {
    return {
      options: assetMovementTypeOptions(),
    }
  },
  patchDownloadStatus() {
    return {
      options: patchDownloadStatusOptions(),
    }
  },
  patchSource() {
    return {
      options: patchSourceOptions(),
    }
  },
  patchStatus() {
    return {
      options: patchStatusOptions(),
    }
  },
  patchUploadStatus() {
    return {
      options: patchUploadStatusOptions(),
    }
  },
  depreciationMethod() {
    return { options: depreciationMethodOptions() }
  },
  healthType() {
    return { options: systemHealthSettingOptions() }
  },
  remoteDeployStage() {
    return { options: remoteDeployStageOptions() }
  },
  configType() {
    return { options: configTypeOptions() }
  },
  taskTypeOptions() {
    return { options: taskTypeOptions() }
  },
  configurationTypeOptions() {
    return { options: configurationTypeOptions() }
  },
  rebootBehaviour() {
    return {
      options: rebootBehaviourOptions(),
    }
  },
  assetTypeEnum() {
    return {
      options: assetTypeOptions(),
    }
  },
  allocationStatus() {
    return {
      options: allocationStatusOptions(),
    }
  },
  minQuantityType() {
    return {
      options: minQualntityTypeOptins(),
    }
  },
  geoLocationPreferenceType() {
    return {
      options: locationPreferenceOptions(),
    }
  },
  patchTypeOptions() {
    return {
      options: patchTypeOptions(),
    }
  },
  approvalModuleSelectorOptions() {
    const __rootTc = getRootPluaralTranslator()
    return {
      options: [
        {
          text: __rootTc('request'),
          key: Constants.REQUEST,
          archived: false,
        },
        {
          text: __rootTc('problem'),
          key: Constants.PROBLEM,
          archived: false,
        },
        {
          text: __rootTc('change'),
          key: Constants.CHANGE,
          archived: false,
        },
        {
          text: __rootTc('release'),
          key: Constants.RELEASE,
          archived: false,
        },
        {
          text: __rootTc('asset_hardware'),
          key: Constants.ASSET_HARDWARE,
          archived: false,
        },
        {
          text: __rootTc('asset_non_it'),
          key: Constants.ASSET_NON_IT,
          archived: false,
        },
        {
          text: __rootTc('asset_movement'),
          key: Constants.ASSET_MOVEMENT,
          archived: false,
        },
        {
          text: __rootTc('knowledge'),
          key: Constants.KNOWLEDGE,
          archived: false,
        },
        {
          text: __rootTc('purchase'),
          key: Constants.PURCHASE,
          archived: false,
        },
      ],
    }
  },
}
export function getVariableOptions(supportedVariable) {
  const options = {}
  Object.keys(supportedVariable).forEach((key) => {
    const operator = transformOperator(key)
    if (operator) {
      options[operator] = Object.keys(supportedVariable[key]).map((k) => ({
        text: supportedVariable[key][k],
        key: k,
      }))
    }
  })
  return options
}

export function transformAvailableQualification(qualification) {
  const operandMap = Invert(OperandTypeMap)
  if (!operandMap[qualification.operandType]) {
    throw new Error(
      `${qualification.operandType} is not found in available operand types`
    )
  }
  let field
  if (qualification.field) {
    field = transformField(qualification.field)
  }
  return {
    label: qualification.displayName,
    value: qualification.key,
    paramName: qualification.paramName,
    valueType: qualification.primitiveType,
    inputType: dropdownTypeMap[qualification.inputTypes]
      ? 'dropdown'
      : qualification.inputTypes,
    ...(dropdownTypeMap[qualification.inputTypes]
      ? dropdownTypeMap[qualification.inputTypes]()
      : {}),
    ...(qualification.unitType ? { unit: qualification.unitType } : {}),
    min: qualification.minHour > 0 ? qualification.minHour : undefined,
    max: qualification.maxHour > 0 ? qualification.maxHour : undefined,
    operandType: operandMap[qualification.operandType],
    operators: [
      ...(qualification.relOperatorsSupport || []),
      ...(qualification.unaryOperatorSupport || []),
    ]
      .map(transformOperator)
      .map((o) => ({
        text: __t(o),
        key: o,
      })),
    ...(field
      ? {
          inputType: buildInputTypeForField(field),
          valueType: qualification.primitiveType,
          customFieldType: field.type,
          ...getAdditionalFieldProps(field),
        }
      : {}),
    ...(qualification.supportedVariable
      ? {
          variableOptions: getVariableOptions(qualification.supportedVariable),
          // variableOptions: Object.keys(
          //   qualification.supportedVariable
          // ).map((k) => ({ text: qualification.supportedVariable[k], key: k })),
        }
      : {}),
    groupName: qualification.groupName || qualification.group,
    fieldDetailsOptions: (qualification.fieldDetails || []).map((i) => ({
      ...i,
      text: i.displayName,
      key: i.referencedField,
    })),
  }
}

export function transformRelationalQualForServer(qualification) {
  if (!qualification.operandType) {
    return null
  }
  if (!OperandTypeMap[qualification.operandType]) {
    throw new Error(
      `${
        OperandTypeMap[qualification.operandType]
      } is not found in available operands types`
    )
  }
  return {
    description: qualification.description,
    qualContext: qualification.qualContext,
    ...buildRelationalQualificationStructure(
      qualification.qualification,
      qualification.operator,
      qualification.toValue
        ? [qualification.value, qualification.toValue]
        : qualification.value,
      qualification.valueType,
      qualification.operandType,
      // add left operand additional params for dependentField is true
      qualification.leftOperandAdditionalParams
    ),
  }
}

export function transformUnaryQualForServer(qualification) {
  if (!qualification.operandType) {
    return null
  }
  if (!OperandTypeMap[qualification.operandType]) {
    throw new Error(
      `${
        OperandTypeMap[qualification.operandType]
      } is not found in available operands types`
    )
  }
  return {
    description: qualification.description,
    qualContext: qualification.qualContext,
    ...buildUnaryQualificationStructure(
      qualification.qualification,
      qualification.operator,
      qualification.operandType,
      // add left operand additional params for dependentField is true
      qualification.leftOperandAdditionalParams
    ),
  }
}

export function transformBinaryQualForServer(left, right, operator) {
  return {
    type: 'BinaryQualificationRest',
    leftQual: left,
    rightQual: right,
    operator,
  }
}

export function transformBatchQualForServer(quals = [], operator) {
  const qualsData = [...quals].filter((q) => q)
  return {
    type: 'BatchQualificationRest',
    quals: qualsData.length ? qualsData : null,
    operator,
  }
}

export function transformQualificationForServer(qualification) {
  if (!qualification) {
    return {}
  }
  const { groups, operators, qualType } = qualification
  // for batch qual tranfer
  if (qualType === 'batchQual') {
    const finalQuals = []
    if (groups.length === 1) {
      const quals = groups[0].operands.map((operand) => {
        if (unaryOperators.indexOf(operand.operator) >= 0) {
          return transformUnaryQualForServer(operand)
        }
        return transformRelationalQualForServer(operand)
      })
      return transformBatchQualForServer(quals, groups[0].operator)
    }
    groups.forEach((group) => {
      const quals = group.operands.map((operand) => {
        if (unaryOperators.indexOf(operand.operator) >= 0) {
          return transformUnaryQualForServer(operand)
        }
        return transformRelationalQualForServer(operand)
      })
      finalQuals.push(transformBatchQualForServer(quals, group.operator))
    })
    return transformBatchQualForServer(finalQuals, operators[0])
  }
  // for binary qual transfer
  return groups.reduce((result, group, index) => {
    let qual, finalResult
    const leftQual = transformRelationalQualForServer(group.operands[0])
    const rightQual = group.operands[1]
      ? transformRelationalQualForServer(group.operands[1])
      : null
    if (!leftQual && !rightQual) {
      return null
    }
    qual = transformBinaryQualForServer(leftQual, rightQual, group.operator)
    if (result) {
      if (result.rightQual) {
        finalResult = transformBinaryQualForServer(
          result,
          qual,
          operators[index - 1]
        )
      } else if (result.leftQual) {
        finalResult = transformBinaryQualForServer(
          result.leftQual,
          qual,
          operators[index - 1]
        )
      } else {
        // first item so no need of having
        finalResult = transformBinaryQualForServer(
          result,
          qual,
          operators[index - 1]
        )
      }
    } else {
      finalResult = qual
    }
    return finalResult
  }, null)
}

export function transformRelationalQual(qual) {
  const operandMap = Invert(OperandTypeMap)
  if (!operandMap[qual.leftOperand.type]) {
    throw new Error(
      `${qual.operandType} is not found in available operand types`
    )
  }
  let value =
    qual.rightOperand && operandMap[qual.rightOperand.type] !== 'variable'
      ? transformValueType(qual.rightOperand.value)
      : qual.rightOperand && operandMap[qual.rightOperand.type] === 'variable'
      ? { value: qual.rightOperand.value, valueType: 'variable' }
      : {}
  const operator = qual.operator ? transformOperator(qual.operator) : null
  if (
    betweenOperators.indexOf(operator) >= 0 &&
    Array.isArray(value.value) &&
    value.value.length
  ) {
    value.toValue = value.value[1]
    value.value = value.value[0]
  }

  if (qual.leftOperand.value === 'agent_activation_status') {
    value = {
      ...value,
      value: String(value.value),
    }
  }

  return {
    description: qual.description,
    ...value,
    operandType: operandMap[qual.leftOperand.type],
    operator,
    qualification: String(
      qual.leftOperand[
        operandMap[qual.leftOperand.type] === 'variable' ? 'value' : 'key'
      ]
    ),
    guid: generateId(),
    // add left operand additional params for dependentField is true
    ...(qual.leftOperand.dependentField
      ? { leftOperandAdditionalParams: { dependentField: true } }
      : {}),
    qualContext: qual.qualContext,
  }
}

export function transformUnaryQual(qual) {
  const operandMap = Invert(OperandTypeMap)
  if (!operandMap[qual.leftOperand.type]) {
    throw new Error(
      `${qual.operandType} is not found in available operand types`
    )
  }
  const operator = qual.operator ? transformOperator(qual.operator) : null

  return {
    description: qual.description,
    operandType: operandMap[qual.leftOperand.type],
    operator,
    qualification: String(
      qual.leftOperand[
        operandMap[qual.leftOperand.type] === 'variable' ? 'value' : 'key'
      ]
    ),
    guid: generateId(),
    // add left operand additional params for dependentField is true
    ...(qual.leftOperand.dependentField
      ? { leftOperandAdditionalParams: { dependentField: true } }
      : {}),
    qualContext: qual.qualContext,
  }
}

export function transformBinaryQual(qual) {
  const transformedQual = {
    operands: [],
    operator: null,
  }
  if (qual.leftQual) {
    transformedQual.operands.push(transformRelationalQual(qual.leftQual))
  }
  if (qual.rightQual) {
    transformedQual.operands.push(transformRelationalQual(qual.rightQual))
  }
  transformedQual.operator = transformOperator(qual.operator)
  return transformedQual
}

export function transformBatchQual(qualification) {
  if (!qualification.quals) {
    return {
      groups: [],
      operators: [],
      qualType: 'batchQual',
    }
  }
  let groups = []
  const operators = []
  const qualTransfer = (qual) => {
    const group = {
      operands: [],
      operator: 'and',
    }
    const qualsList = qual.quals || []
    qualsList.forEach((q) => {
      const transformedQual =
        q.type === 'UnaryQualificationRest'
          ? transformUnaryQual(q)
          : transformRelationalQual(q)
      group.operands.push(transformedQual)
      group.operator = transformOperator(qual.operator || 'and')
    })
    return group
  }
  let relQuals = []
  const qualsList = qualification.quals
  qualsList.forEach((qual) => {
    if (qual.type === 'BatchQualificationRest') {
      groups.push(qualTransfer(qual))
      operators.push(transformOperator(qualification.operator || 'and'))
    } else if (
      qual.type === 'RelationalQualificationRest' ||
      qual.type === 'UnaryQualificationRest'
    ) {
      relQuals.push(qual)
    }
  })
  if (relQuals.length) {
    groups.push(
      qualTransfer({ quals: relQuals, operator: qualification.operator })
    )
    operators.push(transformOperator(qualification.operator || 'and'))
  }

  groups = groups.filter((g) => (g.operands || []).length)
  return { groups, operators, qualType: 'batchQual' }
}

export function transformQualification(qual, groups = [], operators = []) {
  if (!qual) {
    return {
      groups: [],
      operators: [],
      qualType: 'batchQual',
    }
  }
  if (
    qual.type === 'BinaryQualificationRest' &&
    ((qual.leftQual && qual.leftQual.type === 'RelationalQualificationRest') ||
      (qual.rightQual && qual.rightQual.type === 'RelationalQualificationRest'))
  ) {
    // this is group
    const leftQualification =
      qual.leftQual && qual.leftQual.type === 'RelationalQualificationRest'
        ? transformRelationalQual(qual.leftQual)
        : null
    const rightQualification =
      qual.rightQual && qual.rightQual.type === 'RelationalQualificationRest'
        ? transformRelationalQual(qual.rightQual)
        : null
    const operands = []
    if (leftQualification) {
      operands.push(leftQualification)
    }
    if (rightQualification) {
      operands.push(rightQualification)
    }
    const group = {
      operands,
    }
    if (
      qual.leftQual &&
      qual.leftQual.type === 'RelationalQualificationRest' &&
      qual.rightQual &&
      qual.rightQual.type === 'RelationalQualificationRest'
    ) {
      group.operator = qual.operator ? transformOperator(qual.operator) : null
    }
    groups.push(group)
  }
  if (qual.leftQual && qual.leftQual.type === 'BinaryQualificationRest') {
    transformQualification(qual.leftQual, groups, operators)
  }
  if (
    (qual.leftQual && qual.leftQual.type === 'BinaryQualificationRest') ||
    (qual.rightQual && qual.rightQual.type === 'BinaryQualificationRest')
  ) {
    operators.push(transformOperator(qual.operator))
  }
  if (qual.rightQual && qual.rightQual.type === 'BinaryQualificationRest') {
    transformQualification(qual.rightQual, groups, operators)
  }

  if (qual.type === 'BatchQualificationRest') {
    return transformBatchQual(qual)
  }

  return {
    groups,
    operators,
    qualType:
      qual.type === 'BinaryQualificationRest' ? 'binaryQual' : 'batchQual',
  }
}
// export function transformQualification(qual, groups = [], operators = []) {
//   if (!qual) {
//     return {
//       groups: [],
//       operators: [],
//       qualType: 'batchQual',
//     }
//   }
//   if (
//     qual.type === 'BinaryQualificationRest' &&
//     ((qual.leftQual && qual.leftQual.type === 'RelationalQualificationRest') ||
//       (qual.rightQual && qual.rightQual.type === 'RelationalQualificationRest'))
//   ) {
//     // this is group
//     const leftQualification =
//       qual.leftQual && qual.leftQual.type === 'RelationalQualificationRest'
//         ? transformRelationalQual(qual.leftQual)
//         : null
//     const rightQualification =
//       qual.rightQual && qual.rightQual.type === 'RelationalQualificationRest'
//         ? transformRelationalQual(qual.rightQual)
//         : null
//     const operands = []
//     if (leftQualification) {
//       operands.push(leftQualification)
//     }
//     if (rightQualification) {
//       operands.push(rightQualification)
//     }
//     const group = {
//       operands,
//     }
//     if (
//       qual.leftQual &&
//       qual.leftQual.type === 'RelationalQualificationRest' &&
//       qual.rightQual &&
//       qual.rightQual.type === 'RelationalQualificationRest'
//     ) {
//       group.operator = qual.operator ? transformOperator(qual.operator) : null
//     }
//     groups.push(group)
//   }
//   if (qual.leftQual && qual.leftQual.type === 'BinaryQualificationRest') {
//     transformQualification(qual.leftQual, groups, operators)
//   }
//   if (
//     (qual.leftQual && qual.leftQual.type === 'BinaryQualificationRest') ||
//     (qual.rightQual && qual.rightQual.type === 'BinaryQualificationRest')
//   ) {
//     operators.push(transformOperator(qual.operator))
//   }
//   if (qual.rightQual && qual.rightQual.type === 'BinaryQualificationRest') {
//     transformQualification(qual.rightQual, groups, operators)
//   }
//   return {
//     groups,
//     operators,
//     qualType:
//       qual.type === 'BinaryQualificationRest' ? 'binaryQual' : 'batchQual',
//   }
// }

export function buildModuleQuery(moduleName, operator = 'equal') {
  return buildRelationalQualificationStructure(
    'model',
    operator,
    moduleName,
    'enum',
    'db'
  )
}

export function buildNameFilterQuery(name, operator = 'contains') {
  return buildRelationalQualificationStructure(
    'name',
    operator,
    name,
    'string',
    'db'
  )
}

function buildRightOperand(value, valueType) {
  if (
    valueType === 'long' &&
    (value === Constants.CURRENT_DATE_VALUE ||
      value === Constants.CURRENT_DATE_TODAY)
  ) {
    return {
      rightOperand: {
        type: OperandTypeMap['variable'],
        value: 'today',
      },
    }
  }
  if (valueType === 'variable') {
    return {
      rightOperand: {
        type: OperandTypeMap['variable'],
        value,
      },
    }
  }
  if (valueType === 'boolean') {
    return {
      rightOperand: {
        type: 'ValueOperandRest',
        ...transformValueTypeForServer(valueType, value),
      },
    }
  }
  return {
    rightOperand:
      value !== undefined
        ? {
            type: 'ValueOperandRest',
            ...transformValueTypeForServer(valueType, value),
          }
        : null,
  }
}

export function buildRelationalQualificationStructure(
  paramName,
  operator,
  value,
  valueType = 'string',
  paramType = 'property',
  leftOperandAdditionalParams = {}
) {
  valueType = fieldValueOperators.indexOf(operator) >= 0 ? 'field' : valueType
  return {
    type: 'RelationalQualificationRest',
    leftOperand: {
      type: OperandTypeMap[paramType],
      ...(paramType !== 'variable'
        ? { key: isNaN(paramName) ? paramName : Number(paramName) }
        : {}),
      ...(paramType === 'variable' ? { value: paramName } : {}),
      ...(leftOperandAdditionalParams || {}),
    },
    operator: operator ? transformOperatorForServer(operator) : null,
    ...buildRightOperand(value, valueType),
  }
}

export function buildFlatQualificationStructure(quals = []) {
  return {
    type: 'FlatQualificationRest',
    quals: [...quals],
  }
}

export function buildModuleAndNameFilterQuery(moduleName, name) {
  return buildFlatQualificationStructure([
    buildModuleQuery(moduleName),
    ...(name ? [buildNameFilterQuery(name)] : []),
  ])
}

export function buildUnaryQualificationStructure(
  paramName,
  operator,
  paramType = 'property',
  leftOperandAdditionalParams = {}
) {
  return {
    type: 'UnaryQualificationRest',
    leftOperand: {
      type: OperandTypeMap[paramType],
      ...(paramType !== 'variable'
        ? { key: isNaN(paramName) ? paramName : Number(paramName) }
        : {}),
      ...(paramType === 'variable' ? { value: paramName } : {}),
      ...(leftOperandAdditionalParams || {}),
    },
    operator: operator ? transformOperatorForServer(operator) : null,
  }
}

// transfer supported data for decision utility for ai bot
export function transformDecisionAvailableQualification(qualification) {
  // const operandMap = Invert(OperandTypeMap)
  // if (!operandMap[qualification.operandType]) {
  //   throw new Error(
  //     `${qualification.operandType} is not found in available operand types`
  //   )
  // }
  let field
  if (qualification.field) {
    field = transformField(qualification.field)
  }
  return {
    label: qualification.name,
    value: qualification.propKey,
    paramName: qualification.paramName,
    valueType: qualification.primitiveType || 'string',
    inputType: dropdownTypeMap[qualification.inputTypes]
      ? 'dropdown'
      : qualification.inputTypes,
    ...(dropdownTypeMap[qualification.inputTypes]
      ? dropdownTypeMap[qualification.inputTypes]()
      : {}),
    ...(qualification.options
      ? {
          options: Object.keys(qualification.options).map((o) => ({
            key: o,
            text: qualification.options[o],
          })),
        }
      : {}),
    ...(qualification.unitType ? { unit: qualification.unitType } : {}),
    min: qualification.minHour > 0 ? qualification.minHour : undefined,
    max: qualification.maxHour > 0 ? qualification.maxHour : undefined,
    // operandType: operandMap[qualification.operandType],
    operators: (qualification.relOperatorsSupport || [])
      .map(transformOperator)
      .map((o) => ({
        text: __t(o),
        key: o,
      })),
    ...(field
      ? {
          inputType: buildInputTypeForField(field),
          valueType: qualification.primitiveType || 'string',
          customFieldType: field.type,
          ...getAdditionalFieldProps(field),
        }
      : {}),
    ...(qualification.supportedVariable
      ? {
          variableOptions: getVariableOptions(qualification.supportedVariable),
          // variableOptions: Object.keys(
          //   qualification.supportedVariable
          // ).map((k) => ({ text: qualification.supportedVariable[k], key: k })),
        }
      : {}),
    groupName: qualification.groupName,
  }
}
// only for decision qual tranform for server ai bot
export function transformDecisionQualForServer(qualification) {
  return {
    description: qualification.description,
    propKey: qualification.qualification,
    operator: qualification.operator
      ? transformOperatorForServer(qualification.operator)
      : null,
    value: buildRightOperand(
      qualification.toValue
        ? [qualification.value, qualification.toValue]
        : qualification.value,
      qualification.valueType
    ).rightOperand.value,
  }
}
export function transformDecisionQual(qualification) {
  return {
    guid: generateId(),
    qualification: qualification.propKey,
    operator: qualification.operator
      ? transformOperator(qualification.operator)
      : null,
    value:
      betweenOperators.indexOf(qualification.operator) >= 0
        ? qualification.value.value[0]
        : transformValueType(qualification.value).value,
    ...(betweenOperators.indexOf(qualification.operator) >= 0
      ? { toValue: qualification.value.value[1] }
      : {}),
  }
}
// only for aibot decision qualification
export function transformQualificationWithDecisionQualForServer(qualification) {
  if (!qualification) {
    return {}
  }
  const { groups, operators, qualType } = qualification
  // for batch qual tranfer
  if (qualType === 'batchQual') {
    const finalQuals = []
    if (groups.length === 1) {
      const quals = groups[0].operands.map((operand) =>
        transformDecisionQualForServer(operand)
      )
      return transformBatchQualForServer(quals, groups[0].operator)
    }
    groups.forEach((group) => {
      const quals = group.operands.map((operand) =>
        transformDecisionQualForServer(operand)
      )
      finalQuals.push(transformBatchQualForServer(quals, group.operator))
    })
    return transformBatchQualForServer(finalQuals, operators[0])
  }
}

export function transformQualificationWithDecisionQual(
  qualification,
  groups = [],
  operators = []
) {
  if (!qualification) {
    return {
      groups: [],
      operators: [],
      qualType: 'batchQual',
    }
  }
  return {
    groups: [
      {
        guid: generateId(),
        operands: ((qualification || {}).quals || []).map((q) =>
          transformDecisionQual(q)
        ),
        operator: (qualification || {}).operator,
      },
    ],
    operators: [],
    qualType: 'batchQual',
  }
}

export function buildSubQueryQualificationStructure(
  childQualification,
  projectionField,
  sourceModel,
  mappingField,
  operator,
  description
) {
  return {
    type: 'FlotoSubQueryRest',
    childQualification,
    projectionField,
    sourceModel,
    mappingField,
    operator,
    description,
  }
}
